import React, { useCallback, useState } from 'react'
import { GroupBase } from 'react-select'
import { LoadOptions } from 'react-select-async-paginate'

import { useApolloClient } from '@apollo/client'
import { wrapSymbolWithSpan } from 'Utils/Strings'

import { SelectField } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import directSearchOrganizationsQuery from './Queries/directSearchOrganizations.graphql'

export interface OrganizationFieldProps {
  name: string
  label?: string
  placeholder?: string
  isRequired?: boolean
}

type Additional = { page: number }

export interface OrganizationOption {
  label: React.ReactNode
  value: string
  isNew: boolean
}

// TODO: custom styles
function OrganizationField({
  name,
  label,
  placeholder,
  isRequired,
}: OrganizationFieldProps) {
  const client = useApolloClient()
  const { me } = useAppContext()
  const t = useScopedI18n('components.blocks.forms.fields.organizationField')
  const errorT = useScopedI18n('error')
  const [isLoading, setIsLoading] = useState(false)

  const searchOrganizations = useCallback(
    async (inputValue: string, page: number) => {
      try {
        const result = await client.query<
          Pick<MainSchema.Query, 'directSearchOrganizations'>,
          MainSchema.QueryDirectSearchOrganizationsArgs
        >({
          query: directSearchOrganizationsQuery,
          fetchPolicy: 'network-only',
          variables: {
            communityIds: me?.communities?.map(community => community.id) || [],
            query: inputValue,
            limit: 10,
            page,
          },
        })
        const results = result?.data

        return results.directSearchOrganizations
      } catch (error) {
        let message = errorT('generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      }

      return null
    },
    [client, errorT, me?.communities],
  )

  const loadOptions = useCallback<
    LoadOptions<OrganizationOption, GroupBase<OrganizationOption>, Additional>
  >(
    async (inputValue, _, additional) => {
      if (inputValue.length < 3) {
        return {
          options: [],
          hasMore: false,
        }
      }

      setIsLoading(true)
      const page = additional?.page ?? 1

      try {
        const organizations = await searchOrganizations(inputValue, page)
        const newOptions =
          organizations?.edges.map(organizationEdge => ({
            label: (
              <Text
                dangerouslySetInnerHTML={{
                  __html: wrapSymbolWithSpan(
                    organizationEdge.node.name!,
                    inputValue,
                  ),
                }}
                fontSize="14px"
                fontWeight={500}
                lineHeight="20px"
              />
            ),
            value: organizationEdge.node.id,
            isNew: false,
          })) ?? []
        const hasMore =
          !!organizations && page < organizations.pageInfo.totalPages

        return {
          options: newOptions,
          hasMore,
          additional: {
            page: page + 1,
          },
        }
      } catch (error) {
        let message = errorT('generic')
        if (error instanceof Error) {
          message = error.message
        }
        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      } finally {
        setIsLoading(false)
      }

      return {
        options: [],
        hasMore: false,
      }
    },
    [searchOrganizations, errorT],
  )

  const handleIsValidNewOption = useCallback((inputValue: string) => {
    return inputValue.length > 3
  }, [])

  const handleFormatCreateLabel = useCallback(
    (inputValue: string) => {
      return (
        <Text fontSize="14px" fontWeight={600} lineHeight="20px">
          {t('createNew.label.notEmpty', {
            organizationName: inputValue,
          })}
        </Text>
      )
    },
    [t],
  )

  const handleNoOptionsMessage = useCallback(
    (props: { inputValue: string }) => {
      if (props.inputValue.length === 0) {
        return null
      }

      return (
        <Text fontSize="14px" fontWeight={600} lineHeight="20px">
          {t('noOptions.label', {
            organizationName: props.inputValue,
          })}
        </Text>
      )
    },
    [t],
  )

  const handleLoadingMessage = useCallback(() => {
    return (
      <Text fontSize="14px" fontWeight={600} lineHeight="20px">
        {t('loading.label')}
      </Text>
    )
  }, [t])

  const handleGetNewOptionData = useCallback(
    (inputValue: string, optionLabel: string) => {
      return {
        label: optionLabel,
        value: inputValue,
        isNew: true,
      }
    },
    [],
  )

  return (
    name && (
      <>
        <SelectField<
          OrganizationOption,
          false,
          GroupBase<OrganizationOption>,
          Additional
        >
          async
          clearable
          creatable
          createOptionPosition="last"
          debounceTimeout={300}
          formatCreateLabel={handleFormatCreateLabel}
          getNewOptionData={handleGetNewOptionData}
          isLoading={isLoading}
          isValidNewOption={handleIsValidNewOption}
          label={label}
          loadOptions={loadOptions}
          loadingMessage={handleLoadingMessage}
          name={name}
          noOptionsMessage={handleNoOptionsMessage}
          paginated
          placeholder={placeholder}
          required={isRequired}
          withPortal
        />
      </>
    )
  )
}

export default OrganizationField
