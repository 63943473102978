import { useMemo } from 'react'

import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import { ActionsCell, AgreementCell, CheckboxCell } from './Cells'
import { CheckAllHeader } from './Headers'

function renderFullName({ row }) {
  return getFullName(row?.original)
}

export function useColumns({ showHeader = true, isUserTable = false }) {
  return useMemo(() => {
    const columns = [
      {
        id: 'user',
        Header: CheckAllHeader,
        Cell: CheckboxCell,
        width: '16px',
        disableSortBy: true,
      },
      {
        Header: 'Name',
        id: 'firstName',
        Cell: renderFullName,
        width: 1.5,
        canSort: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 1.5,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 0.5,
        disableSortBy: true,
      },
      ...(isUserTable
        ? [
            {
              Header: 'Terms & Privacy',
              Cell: AgreementCell,
              width: 0.5,
              disableSortBy: true,
            },
            {
              Header: 'Role',
              accessor: 'communityRole.name',
              width: 0.5,
              disableSortBy: true,
            },
          ]
        : []),
      ...(!isUserTable
        ? [
            {
              Header: 'Created at',
              accessor: 'createdAt',
              Cell: Utils.Table.renderDateTimeCell,
              width: 1,
            },
          ]
        : []),
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.9,
        cellRight: true,
        headerRight: true,
        disableSortBy: true,
      },
    ]

    if (!showHeader) columns.shift()

    return columns
  }, [isUserTable, showHeader])
}
