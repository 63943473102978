import { createContext, useContext } from 'react'

import noop from 'lodash/noop'

import { SORT_BY_LABELS, SortByLabel } from 'Constants/ids'

export interface IOptions {
  // @TODO: this interface is duplicated several times
  users: string[]
  skills: string[]
  organizations: string[]
  tags: string[]
  knowledge: string[]
  communities: string[]
  explore: string[]
}

export interface IDashboardContext {
  options: IOptions | undefined
  sortBy: SortByLabel
  searchQuery: string
  totalSearchResults: number
  setTotalSearchResults: React.Dispatch<React.SetStateAction<number>>
  searchKeywords: string[]
  setSearchKeywords: React.Dispatch<React.SetStateAction<string[]>>
  selectedCommunityIds: string[]
  setSelectedCommunityIds: (ids: string[]) => void
}

export const DashboardContext = createContext<IDashboardContext>({
  options: undefined,
  sortBy: SORT_BY_LABELS.RECENTLY_CREATED,
  searchQuery: '',
  totalSearchResults: 0,
  setTotalSearchResults: noop,
  searchKeywords: [],
  setSearchKeywords: noop,
  selectedCommunityIds: [],
  setSelectedCommunityIds: noop,
})

export function useDashboardContext() {
  return useContext(DashboardContext)
}
