import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { IconCalendar, IconEye, IconLock, IconX } from '@tabler/icons-react'
import useAbility from 'Features/Notes/useAbility'
import readNoteMutation from 'GraphQL/Mutations/Notes/readNote.graphql'
import noteQuery from 'GraphQL/Queries/Notes/note.graphql'
import { readNoteUpdater } from 'GraphQL/Updaters/Note'
import { DateTime } from 'luxon'
import Utils from 'Utils'
import stringReplacer from 'Utils/stringReplacer'
import { getFullName } from 'Utils/User'

import { Button, Column, IconButton, Loader, Row, Text } from 'Components/UI'

import { REDIRECT_BACK } from 'Constants/ids'
import { notesReplacers } from 'Constants/replacers'

import { useCommunity } from 'Hooks'

import { COMMUNITY_NOTE } from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Container, Divider, NoteContainer, ScrollContainer } from './styles'

import { TableActions } from '../../Blocks'

export interface INoteReaderProps {
  noteId: string
  onClose: MouseEventHandler<HTMLButtonElement>
  onUpdateFavorite: (noteId: string, isFavorite: boolean) => void
}

function NoteReader({ noteId, onClose, onUpdateFavorite }: INoteReaderProps) {
  const s = useScopedI18n('notesManagement.notesReader')
  const navigate = useNavigate()
  const { community } = useCommunity()

  const communityId = community?.id

  const { data, loading } = useQuery<
    Pick<MainSchema.Query, 'note'>,
    MainSchema.QueryNoteArgs
  >(noteQuery, {
    skip: !noteId || !communityId,
    variables: communityId ? { noteId, communityId } : undefined,
  })
  const noteData = data?.note

  const [readNote] = useMutation<
    Pick<MainSchema.Mutation, 'readNote'>,
    MainSchema.MutationReadNoteArgs
  >(readNoteMutation)

  useEffect(() => {
    async function handleReadNote() {
      if (!communityId || !noteData) {
        return
      }

      try {
        await readNote({
          variables: {
            noteId: noteData.id,
            communityId,
          },
          update: readNoteUpdater(noteData),
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: s('title'),
          text: message,
        })
      }
    }

    if (noteData?.read === false) {
      handleReadNote()
    }
  }, [noteData, s, readNote, communityId])

  const handleAddFavorite = useCallback(() => {
    if (!noteData) {
      return
    }

    onUpdateFavorite(noteData.id, !noteData.favorite)
  }, [noteData, onUpdateFavorite])

  const handleEditNote = useCallback(() => {
    navigate({
      pathname: Utils.URL.generatePath(COMMUNITY_NOTE, {
        slug: community?.slug,
        noteId: noteData?.id,
      }),
      search: `redirect=${REDIRECT_BACK.MANAGEMENT_NOTES}`,
    })
  }, [community?.slug, navigate, noteData?.id])

  const noteType = useMemo(() => {
    if (!noteData?.public)
      return (
        <Row center gap={1}>
          <IconLock size={20} stroke={1} />
          {_('notesManagement.status.private')}
        </Row>
      )

    if (noteData?.public)
      return (
        <Row center gap={1}>
          <IconEye size={20} stroke={1} />
          {_('notesManagement.status.public')}
        </Row>
      )

    return null
  }, [noteData])

  const { isCreatorMe } = useAbility()

  return (
    <Container>
      <Row center gap={2} px={5} py={2} spaceBetween>
        <Row center>
          <IconButton secondary small onClick={onClose}>
            <IconX />
          </IconButton>

          <Text header header3>
            {s('title')}
          </Text>
        </Row>

        <TableActions
          canToggleFavorite={noteData && isCreatorMe(noteData)}
          isFavorite={noteData?.favorite}
          onFavoriteToggle={handleAddFavorite}
        />
      </Row>

      <Divider />

      {loading || !noteData ? (
        <Column center fullWidth px={5} py={5}>
          <Loader />
        </Column>
      ) : (
        <>
          <Column fullWidth grow pb={3} pt={2} px={5}>
            <Row center mb={4} spaceBetween>
              <Row center gap={2}>
                <IconCalendar size={16} stroke={1} />

                <Text bodyMedium header>
                  {DateTime.fromISO(noteData.createdAt).toLocaleString(
                    DateTime.DATE_SHORT,
                  )}
                </Text>
              </Row>
              <Row center>{noteType}</Row>
            </Row>

            <Text body bodyMedium mb="2px">
              {s('creator')}
            </Text>

            <Text header header4 mb={4}>
              {getFullName(noteData.creator?.profile)}
              {isCreatorMe(noteData) && (
                <Text as="span" body bodyMedium>
                  &nbsp;({s('you')})
                </Text>
              )}
            </Text>

            <Text body bodyMedium mb="2px">
              {s('subject')}
            </Text>

            {/* TODO: remove during cleanup of profile identity sources */}
            {noteData.targetEntity?.__typename === 'User' && (
              <Text header header4>
                {getFullName(noteData.targetEntity.profile)}
              </Text>
            )}

            {noteData.targetEntity?.__typename === 'CommunityUser' && (
              <Text header header4>
                {getFullName(noteData.targetEntity)}
              </Text>
            )}

            {noteData.targetEntity?.__typename === 'Tag' && (
              <Text header header4>
                {noteData.targetEntity.name}
              </Text>
            )}
          </Column>

          <Divider />

          <NoteContainer>
            <ScrollContainer>
              <Text bodyMedium header>
                {stringReplacer(noteData.content, notesReplacers)}
              </Text>
            </ScrollContainer>

            <Row noShrink>
              <Button width={1} onClick={handleEditNote}>
                {s('editNote')}
              </Button>
            </Row>
          </NoteContainer>
        </>
      )}
    </Container>
  )
}

export default NoteReader
