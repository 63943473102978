import React, { useCallback } from 'react'
import { MultiValueRemoveProps } from 'react-select'

import { pick } from '@styled-system/props'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import communitySkillsQuery from 'GraphQL/Queries/Community/communitySkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { ITagOption, tagsToOptions } from 'Utils/Options'

import { SelectField, TagMultiValueRemove } from 'Components/UI'
import { ISelectField } from 'Components/UI/Form/Select/SelectField'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  SEARCH_TYPES,
  SearchType,
} from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import toast from 'Services/Toast'

export interface ITagsField extends ISelectField<ITagOption, true> {
  type: SearchType
}

function TagsField({
  isOptionDisabled = () => false,
  label,
  name,
  placeholder,
  required = false,
  type,
  ...rest
}: ITagsField) {
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const loadTagsOptions = useCallback(
    () =>
      async (
        inputValue: string,
        callback: (...args: any[]) => Promise<void> | void,
      ) => {
        const isSkill = type === SEARCH_TYPES.skill

        try {
          const result = await client.query({
            query: isSkill ? communitySkillsQuery : listTagsQuery,
            variables: {
              communityIds: [community?.id],
              kind: isSkill ? undefined : type,
              search: inputValue,
              limit: 25,
            },
          })
          const suggestions = result?.data
          const tags = isSkill
            ? {
                ...suggestions?.communitySkills?.rows,
              }
            : { ...suggestions?.listTags?.rows }
          callback(tagsToOptions(tags, type))
        } catch (error) {
          if (error instanceof Error) {
            toast.error({
              title: `The server returned an error: "${error.message}"`,
              text: error.message,
            })
          }
        }
      },
    [client, type, community],
  )

  const debouncedLoadTagsOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadTagsOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadTagsOptions],
  )

  const renderClearIndicator = useCallback(
    (props: MultiValueRemoveProps) => (
      <TagMultiValueRemove {...props} tagColorKind={type} />
    ),
    [type],
  )

  return (
    <SelectField<ITagOption, true>
      {...pick(rest)}
      async
      components={{
        MultiValueRemove: renderClearIndicator,
      }}
      isMulti
      isOptionDisabled={isOptionDisabled}
      label={label}
      loadOptions={debouncedLoadTagsOptions()}
      name={name}
      placeholder={placeholder}
      required={required}
    />
  )
}

export default TagsField
