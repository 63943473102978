export const ROOT = `/`
export const REDIRECTOR = `${ROOT}redirector`

export const ONBOARDING = `${ROOT}onboarding`
export const ONBOARDING_WELCOME = `${ROOT}welcome`
export const ONBOARDING_CONNECT = `${ROOT}connect`

export const COMMUNITY = `${ROOT}community`
export const COMMUNITY_SLUG = `${ROOT}community/:slug`
export const COMMUNITY_DASHBOARD = `${COMMUNITY_SLUG}/dashboard`
export const COMMUNITY_USER = `${COMMUNITY_DASHBOARD}/user/:userId`
export const COMMUNITY_NOTE = `${COMMUNITY_SLUG}/note/:noteId`
export const COMMUNITY_SEARCH = `${COMMUNITY_SLUG}/search`
export const COMMUNITY_MANAGE = `${COMMUNITY_SLUG}/manage`
export const COMMUNITY_SUGGESTIONS = `${COMMUNITY_SLUG}/suggestions`
export const COMMUNITY_INVITE = `${COMMUNITY_SLUG}/invite`
export const COMMUNITY_ADD = `${COMMUNITY_SLUG}/add`
export const COMMUNITY_NEW = `${COMMUNITY}/new`
export const COMMUNITY_MANAGEMENT = `${COMMUNITY_SLUG}/management`
export const COMMUNITY_HUBSPOT_OAUTH = `${ROOT}community/hubspot/oauth`

export const PROFILE_ROOT = `${ROOT}profile`
export const AUTH_ROOT = `${ROOT}auth`
export const AUTH_SIGN_IN = `${AUTH_ROOT}/sign-in`
export const AUTH_SIGN_UP = `${AUTH_ROOT}/sign-up`
export const AUTH_CHECK_MAGIC_LINK = `${AUTH_ROOT}/magic-link`
export const SOCIAL_CALLBACK = `${ROOT}callback/:social`

export const SIGN_OUT = `${ROOT}sign-out`
export const ACCEPT_COMMUNITY_INVITE = `${ROOT}acceptCommunityInvite`
export const VERIFY_EMAIL = `${ROOT}verify-email`
export const ADMIN_ROOT = `${ROOT}admin`
export const ADMIN_ADMINS = `${ADMIN_ROOT}/admins`
export const ADMIN_USERS = `${ADMIN_ROOT}/users`
export const ADMIN_SKILLS = `${ADMIN_ROOT}/skills`
export const ADMIN_COMMUNITIES = `${ADMIN_ROOT}/communities`
export const ADMIN_COMMUNITY_USERS = `${ADMIN_ROOT}/community/:communityId/users`
export const ADMIN_COMMUNITY_RBA = `${ADMIN_ROOT}/community/:communityId/rba`

export const ADMIN_CHANGE_REPORT_COMMUNITIES = `${ADMIN_ROOT}/change-reports`
export const ADMIN_CHANGE_REPORTS = `${ADMIN_ROOT}/change-reports/:communityId`
export const ADMIN_CHANGE_REPORTS_UPLOAD = `${ADMIN_ROOT}/change-reports/:communityId/upload`

export const UI_KIT = `${ROOT}ui`

export const OAUTH_CALLBACK = `${ROOT}oauth/callback`

export const NOT_FOUND = `${ROOT}404`
