import React, { useMemo } from 'react'

import { IconSquareRounded } from '@tabler/icons-react'

import capitalize from 'lodash/capitalize'

import { Column, Row, Tag, Text } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

function Tags() {
  const icon = useMemo(() => <IconSquareRounded />, [])

  return (
    <Column gap={6}>
      <Text header1>Tags</Text>
      <Row center gap={5}>
        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.USER}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.USER)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.USER}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.USER)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.CONNECT}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.CONNECT)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.CONNECT}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.CONNECT)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.SKILL}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.SKILL)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.SKILL}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.SKILL)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.EVENT}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.EVENT)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.EVENT}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.EVENT)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.PROJECT}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.PROJECT)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.PROJECT}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.PROJECT)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.GROUP}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.GROUP)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.GROUP}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.GROUP)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.ROLE}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.ROLE)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.ROLE}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.ROLE)}
          />
        </Column>

        <Column center gap={3}>
          <Tag
            colorKind={TAG_COLOR_KIND.CUSTOM}
            renderAfter={icon}
            renderBefore={icon}
            text={capitalize(TAG_COLOR_KIND.CUSTOM)}
          />
          <Tag
            colorKind={TAG_COLOR_KIND.CUSTOM}
            renderAfter={icon}
            renderBefore={icon}
            small
            text={capitalize(TAG_COLOR_KIND.CUSTOM)}
          />
        </Column>
      </Row>
    </Column>
  )
}

export default Tags
