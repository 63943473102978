import React from 'react'

import map from 'lodash/map'

import Tag from 'Components/UI/Tag'

import { TAG_COLOR_KIND, TagColorKind } from 'Constants/tags'

import { getFullName } from './User'

export interface ICommunityUserOption {
  value: string | undefined
  label: React.ReactNode
}

// TODO: These should be specialized options, not a global utility
export function usersToOptions(
  users: MainSchema.CommunityUser[],
): ICommunityUserOption[] {
  return map(users, user => ({
    value: user?.userId,
    label: (
      <Tag colorKind={TAG_COLOR_KIND.USER} small text={getFullName(user)} />
    ),
  }))
}

// TODO: These should be specialized options, not a global utility
export function communityUsersToOptions(
  communityUsers: MainSchema.CommunityUser[],
): ICommunityUserOption[] {
  return map(communityUsers, communityUser => ({
    value: communityUser.id,
    label: (
      <Tag
        colorKind={TAG_COLOR_KIND.USER}
        small
        text={getFullName(communityUser)}
      />
    ),
  }))
}

export interface IEntity {
  id?: string
  uuid?: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  disabled?: boolean
}

function getEntityName(entity: IEntity): string {
  if (entity.name) {
    return entity.name
  }
  if (entity.firstName) {
    return `${entity.firstName} ${entity.lastName}`
  }
  if (entity.email) {
    return entity.email
  }

  return 'Undefined'
}

export interface IEntityOption {
  id: string | undefined
  label: React.ReactNode
  value: string | undefined
  disabled: boolean
}

export function entitiesToOptions(entities: IEntity[]): IEntityOption[] {
  return map(entities, entity => ({
    id: entity.id || entity.uuid,
    label: getEntityName(entity),
    value: entity.id || entity.uuid,
    disabled: entity?.disabled || false,
  }))
}

export interface ITagOptionInput {
  id: string
  name?: string
  value?: string
  kind?: TagColorKind
  disabled?: boolean
}

export interface ISkillOptionInput {
  id: string
  name?: string
  value?: string
  kind?: TagColorKind
  disabled?: boolean
}

export interface ICommunityOptionInput {
  id: string
  name?: string
  value?: string
  disabled?: boolean
}

export interface ITagOption {
  id: string
  name?: string
  value?: string
  label?: React.ReactNode
  kind?: TagColorKind | string
  disabled?: boolean
}

export interface ISkillOption {
  id: string
  name?: string
  value?: string
  label?: React.ReactNode
  kind?: TagColorKind | string
  disabled?: boolean
}

export interface ICommunityOption {
  id: string
  name?: string
  value?: string
  label?: React.ReactNode
  disabled?: boolean
}

export function tagsToOptions(
  tags: ITagOptionInput[] | ISkillOptionInput[],
  tagColorKind?: TagColorKind,
): ITagOption[] | ISkillOption[] {
  return map(tags, tag => tagToOption(tag, tagColorKind))
}

export function tagToOption(
  tag: ITagOptionInput | ISkillOptionInput,
  tagColorKind?: TagColorKind,
): ITagOption | ISkillOption {
  return {
    id: tag.id,
    label: (
      <Tag
        colorKind={tag.kind ?? tagColorKind ?? TAG_COLOR_KIND.CUSTOM}
        passClickThrough
        small
        text={tag.name}
      />
    ),
    value: tag.id,
    disabled: tag.disabled ?? false,
    name: tag.name ?? '',
    kind: tag.kind,
  }
}

export function communitiesToOptions(
  communities: ICommunityOptionInput[],
): ICommunityOption[] {
  return map(communities, tag => communityToOption(tag))
}

export function communityToOption(
  community: ICommunityOptionInput,
): ITagOption | ISkillOption {
  return {
    id: community.id,
    label: (
      <Tag
        colorKind={TAG_COLOR_KIND.COMMUNITY}
        passClickThrough
        small
        text={community.name}
      />
    ),
    value: community.id,
    disabled: community.disabled ?? false,
    name: community.name ?? '',
  }
}
