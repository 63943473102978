import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import { IGraphOrganizationNode } from 'Features/GraphNodes/NodeTypes'
import createOrganizationMutation from 'GraphQL/Mutations/Organization/createOrganization.graphql'
import validate from 'validate.js'

import OrganizationField, {
  OrganizationOption,
} from 'Components/Blocks/Forms/Fields/OrganizationField/OrganizationField'
import { Column, Icon, InputField, Modal, Row } from 'Components/UI'

import {
  FACEBOOK_REGEX,
  LINKEDIN_COMPANY_REGEX,
  TWITTER_REGEX,
  URL_REGEX,
} from 'Constants/regex'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum CreateOrganizationFormField {
  Organization = 'organization',
  OrganizationWebsiteUrl = 'organizationWebsiteUrl',
  OrganizationLinkedInUrl = 'organizationLinkedInUrl',
  OrganizationTwitterUrl = 'organizationTwitterUrl',
  OrganizationFacebookUrl = 'organizationFacebookUrl',
}

interface CreateOrganizationFormValues {
  [CreateOrganizationFormField.Organization]: OrganizationOption
  [CreateOrganizationFormField.OrganizationWebsiteUrl]: string
  [CreateOrganizationFormField.OrganizationLinkedInUrl]: string
  [CreateOrganizationFormField.OrganizationTwitterUrl]: string
  [CreateOrganizationFormField.OrganizationFacebookUrl]: string
}

export interface CreateOrganizationModalProps {
  isOpen: boolean
  onClose: (success: boolean) => void
}

function CreateOrganizationModal({
  isOpen,
  onClose,
}: CreateOrganizationModalProps) {
  const t = useScopedI18n('components.blocks.modals.createOrganization')
  const [isLoading, setIsLoading] = useState(false)
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnOrganization } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()

  const [createOrganization] = useMutation<
    Pick<MainSchema.Mutation, 'createOrganization'>,
    MainSchema.MutationCreateOrganizationArgs
  >(createOrganizationMutation)

  const createOrganizationFormConstraints = useMemo(() => {
    return {
      [CreateOrganizationFormField.Organization]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.organization.validation.required')}`,
        },
      },
      [CreateOrganizationFormField.OrganizationWebsiteUrl]: {
        type: 'string',
        format: {
          pattern: URL_REGEX,
          message: `^${t('form.organizationWebsiteUrl.validation.invalid')}`,
        },
      },
      [CreateOrganizationFormField.OrganizationLinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_COMPANY_REGEX,
          message: `^${t('form.organizationLinkedInUrl.validation.invalid')}`,
        },
      },
      [CreateOrganizationFormField.OrganizationTwitterUrl]: {
        type: 'string',
        format: {
          pattern: TWITTER_REGEX,
          message: `^${t('form.organizationTwitterUrl.validation.invalid')}`,
        },
      },
      [CreateOrganizationFormField.OrganizationFacebookUrl]: {
        type: 'string',
        format: {
          pattern: FACEBOOK_REGEX,
          message: `^${t('form.organizationFacebookUrl.validation.invalid')}`,
        },
      },
    }
  }, [t])

  const customValidate = (values: CreateOrganizationFormValues) => {
    const errors = validate(values, createOrganizationFormConstraints)
    const organization = values[CreateOrganizationFormField.Organization]
    const organizationWebsiteUrl =
      values[CreateOrganizationFormField.OrganizationWebsiteUrl]
    const organizationLinkedInUrl =
      values[CreateOrganizationFormField.OrganizationLinkedInUrl]
    const organizationTwitterUrl =
      values[CreateOrganizationFormField.OrganizationTwitterUrl]
    const organizationFacebookUrl =
      values[CreateOrganizationFormField.OrganizationFacebookUrl]

    if (organization && !organization.isNew) {
      return {
        ...errors,
        [CreateOrganizationFormField.Organization]: [
          t('form.organization.validation.unique'),
        ],
      }
    }

    if (
      !organizationWebsiteUrl &&
      !organizationLinkedInUrl &&
      !organizationTwitterUrl &&
      !organizationFacebookUrl
    ) {
      return {
        ...errors,
        [CreateOrganizationFormField.OrganizationWebsiteUrl]: [
          t('form.organization.validation.socialUrlRequired'),
        ],
        [CreateOrganizationFormField.OrganizationLinkedInUrl]: [
          t('form.organization.validation.socialUrlRequired'),
        ],
        [CreateOrganizationFormField.OrganizationTwitterUrl]: [
          t('form.organization.validation.socialUrlRequired'),
        ],
        [CreateOrganizationFormField.OrganizationFacebookUrl]: [
          t('form.organization.validation.socialUrlRequired'),
        ],
      }
    }

    return errors
  }

  const submit = useCallback(
    async (values: CreateOrganizationFormValues) => {
      setIsLoading(true)

      try {
        const organization = values[CreateOrganizationFormField.Organization]
        const organizationWebsiteUrl =
          values[CreateOrganizationFormField.OrganizationWebsiteUrl]
        const organizationLinkedInUrl =
          values[CreateOrganizationFormField.OrganizationLinkedInUrl]
        const organizationTwitterUrl =
          values[CreateOrganizationFormField.OrganizationTwitterUrl]
        const organizationFacebookUrl =
          values[CreateOrganizationFormField.OrganizationFacebookUrl]

        if (organization) {
          const organizationResult = await createOrganization({
            variables: {
              input: {
                name: organization.value,
                websiteUrl: organizationWebsiteUrl,
                linkedInUrl: organizationLinkedInUrl,
                twitterUrl: organizationTwitterUrl,
                facebookUrl: organizationFacebookUrl,
              },
            },
          })

          if (organizationResult.data?.createOrganization.id) {
            const organizationId = organizationResult.data.createOrganization.id
            if (!organizationId) {
              throw new Error('organizationId is required')
            }

            // update graph
            if (isFeatureEnabled('regraph')) {
              handleSpawnOrganization([
                organizationResult.data
                  .createOrganization as IGraphOrganizationNode,
              ])
              focusNodes([organizationResult.data.createOrganization.id])
            } else {
              EventBus.trigger(EventBus.actions.graph.addOrganizationById, {
                id: organizationId,
                isSelected: true,
              })
            }
          }
        }

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      createOrganization,
      focusNodes,
      handleSpawnOrganization,
      isFeatureEnabled,
      onClose,
      t,
    ],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid,
    }: FormRenderProps<CreateOrganizationFormValues>) => {
      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen={isOpen}
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(false)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <OrganizationField
              isRequired
              label={t('form.organization.label')}
              name={CreateOrganizationFormField.Organization}
              placeholder={t('form.organization.placeholder')}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={t('form.organizationWebsiteUrl.label')}
              name={CreateOrganizationFormField.OrganizationWebsiteUrl}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row alignItems="center" gap={2}>
                  {t('form.organizationLinkedInUrl.label')}
                  <Icon.LinkedIn size={10} />
                </Row>
              }
              name={CreateOrganizationFormField.OrganizationLinkedInUrl}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row alignItems="center" gap={2}>
                  {t('form.organizationTwitterUrl.label')}
                  <Icon.Twitter size={10} />
                </Row>
              }
              name={CreateOrganizationFormField.OrganizationTwitterUrl}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row alignItems="center" gap={2}>
                  {t('form.organizationFacebookUrl.label')}
                  <Icon.Facebook size={10} />
                </Row>
              }
              name={CreateOrganizationFormField.OrganizationFacebookUrl}
            />
          </Column>
        </Modal>
      )
    },
    [isLoading, isOpen, onClose, t],
  )

  return (
    <Form<CreateOrganizationFormValues>
      render={renderForm}
      validate={customValidate}
      onSubmit={submit}
    />
  )
}

export default CreateOrganizationModal
