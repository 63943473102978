import React from 'react'

import { pick } from '@styled-system/props'

import { IconX } from '@tabler/icons-react'

import Text from 'Components/UI/Text'

import { Container, IContainer, ITagMouseEvent, RemoveButton } from './styles'

export interface ITag extends IContainer {
  removable?: boolean
  text?: string
  entity?: any
  passClickThrough?: boolean
  renderAfter?: React.ReactNode
  renderBefore?: React.ReactNode
  onRemove?: (e: ITagMouseEvent) => void
  onClick?: (e: ITagMouseEvent) => void
}

function Tag({
  colorKind,
  maxWidth,
  removable = false,
  renderAfter,
  renderBefore,
  small = false,
  passClickThrough = false,
  text,
  entity,
  onClick,
  onRemove,
  ...rest
}: ITag) {
  const handleOnClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      onClick?.({
        ...e,
        entity,
      } as ITagMouseEvent)
    },
    [entity, onClick],
  )

  const handleOnRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onRemove?.({
        ...e,
        entity,
      } as ITagMouseEvent)
    },
    [entity, onRemove],
  )

  if (!text) return null

  return (
    <Container
      {...pick(rest)}
      colorKind={colorKind}
      maxWidth={maxWidth}
      passClickThrough={passClickThrough}
      small={small}
      onClick={handleOnClick}
    >
      {renderBefore}
      <Text ellipsis tagLarge={!small} tagSmall={small}>
        {text}
      </Text>
      {renderAfter}

      {removable && (
        <RemoveButton onClick={handleOnRemove}>
          <IconX />
        </RemoveButton>
      )}
    </Container>
  )
}

export default Tag
