import { useMemo } from 'react'

import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import { ActionsCell, CreatorCell } from './Cells'

function renderFullName({ row }) {
  return getFullName(row?.original)
}

export function useColumns() {
  return useMemo(() => {
    const columns = [
      {
        Header: 'Name',
        Cell: renderFullName,
        width: 1.5,
      },

      {
        Header: 'Community role',
        accessor: 'communityRole.name',
        width: 0.5,
      },
      {
        Header: 'Сreator',
        accessor: 'creator',
        Cell: CreatorCell,
        width: 1,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ]

    return columns
  }, [])
}
