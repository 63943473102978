import { ApolloCache, ApolloClient } from '@apollo/client'
import unreadNotificationsCount from 'GraphQL/Queries/Community/unreadNotificationsCount.graphql'

export const unreadNotificationsIncreaseCountUpdater = (
  store: ApolloClient<Pick<MainSchema.Subscription, 'notificationsPayload'>>,
  communityId?: string,
) => {
  if (!communityId) {
    return
  }

  const query = {
    query: unreadNotificationsCount,
    variables: {
      communityId,
    },
  }

  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'notificationsCount'>,
    MainSchema.QueryNotificationsCountArgs
  >(query)

  if (queryData) {
    store.writeQuery<
      Pick<MainSchema.Query, 'notificationsCount'>,
      MainSchema.QueryNotificationsCountArgs
    >({
      ...query,
      data: {
        notificationsCount: {
          // eslint-disable-next-line no-unsafe-optional-chaining
          count: queryData?.notificationsCount?.count + 1,
        },
      },
    })
  }
}

export const unreadNotificationsDecreaseCountUpdater = (
  store: ApolloCache<Pick<MainSchema.Mutation, 'notificationsUpdate'>>,
  communityId: string,
  count?: number,
) => {
  const query = {
    query: unreadNotificationsCount,
    variables: {
      communityId,
    },
  }

  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'notificationsCount'>,
    MainSchema.QueryNotificationsCountArgs
  >(query)

  if (queryData) {
    store.writeQuery<
      Pick<MainSchema.Query, 'notificationsCount'>,
      MainSchema.QueryNotificationsCountArgs
    >({
      ...query,
      data: {
        notificationsCount: {
          ...queryData.notificationsCount,
          // eslint-disable-next-line no-unsafe-optional-chaining
          count: count ?? queryData?.notificationsCount?.count - 1,
        },
      },
    })
  }
}
