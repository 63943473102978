import React, { useMemo } from 'react'
import { Column } from 'react-table'

import Utils from 'Utils'

import {
  ActionsCell,
  NoteContentCell,
  NoteSelectCell,
  // StatusCell,
  TypeCell,
} from './Cells'
import { SelectAllHeader } from './Headers'

export function useColumns() {
  return useMemo(() => {
    const columns: Column<MainSchema.Note>[] = [
      {
        id: 'select',
        Header: SelectAllHeader,
        Cell: NoteSelectCell,
        width: '16px',
      },
      {
        id: 'type',
        Header: '',
        Cell: TypeCell,
        width: 0.2,
        disableSortBy: true,
        cellCenter: true,
      },
      {
        Header: 'Note',
        Cell: NoteContentCell,
        width: 2,
        disableSortBy: true,
      },
      {
        Header: 'Creator',
        accessor: 'creator',
        Cell: Utils.Table.renderProfileFullName,
        width: 1,
        disableSortBy: true,
      },
      {
        Header: 'Subject',
        accessor: 'targetEntity',
        Cell: props => {
          // TODO: remove during cleanup of profile identity sources
          if (props.value?.__typename === 'User') {
            return <>{Utils.User.getFullName(props.value.profile)}</>
          }

          if (props.value?.__typename === 'CommunityUser') {
            return <>{Utils.User.getFullName(props.value)}</>
          }

          if (props.value?.__typename === 'Tag') {
            return <>{props.value.name}</>
          }

          return null
        },
        width: 1,
        disableSortBy: true,
      },
      /*
      {
        Header: 'Status',
        accessor: 'public',
        Cell: StatusCell,
        width: 1,
        disableSortBy: true,
      },
      */
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
        defaultCanSort: true,
      },
      {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
        defaultCanSort: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ActionsCell,
        width: 0.6,
        disableSortBy: true,
        cellRight: true,
        headerRight: true,
      },
    ]

    return columns
  }, [])
}
