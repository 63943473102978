import React, { useRef, useState } from 'react'

import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import CreateOrganizationModal from 'Components/Blocks/Modals/CreateOrganizationModal/CreateOrganizationModal'
import CreateSkillTagModal from 'Components/Blocks/Modals/CreateSkillTagModal/CreateSkillTagModal'
import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemHeader,
  Tooltip,
} from 'Components/UI'

import { SkillKind, SkillTagKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useEntityModal, useOnClickOutside } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './styles'

import { IconButtonStyled } from '../styles'

const CreateNewButton = () => {
  const t = useScopedI18n(
    'components.blocks.communityDashboard.createNewButton',
  )
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [skillTagType, setSkillTagType] = useState<SkillTagKind | null>(null)

  const [createModal, createActions] = useEntityModal()
  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal

  const [createSkillTagModal, createSkillTagActions] = useEntityModal()
  const handleCreateSkillTagModalOpen = (skillTag: SkillTagKind) => {
    setSkillTagType(skillTag)
    createSkillTagActions.openModal()
  }
  const handleCreateSkillTagModalClose = createSkillTagActions.closeModal

  const [createOrganizationModal, createOrganizationActions] = useEntityModal()
  const handleCreateOrganizationModalOpen = createOrganizationActions.openModal
  const handleCreateOrganizationModalClose =
    createOrganizationActions.closeModal

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <>
      <Styled.Wrapper ref={ref}>
        <Tooltip content={t('createNew')}>
          <IconButtonStyled
            tertiary
            onClick={() => setIsOpen(prevState => !prevState)}
          >
            <Styled.PlusIcon />
          </IconButtonStyled>
        </Tooltip>

        <ContextMenu isOpen={isOpen} left="auto" right={0} top="auto">
          <ContextMenuItemHeader>{t('createNew')}</ContextMenuItemHeader>
          <ContextMenuItem
            iconPrefix="contacts"
            id="CREATE_USER"
            label={t('contact')}
            onClick={handleCreateModalOpen}
          />
          <ContextMenuItem
            iconPrefix="skillTag"
            id="CREATE_SKILL"
            label={t('skill')}
            onClick={() => handleCreateSkillTagModalOpen(SkillKind.Skill)}
          />
          <ContextMenuItem
            iconPrefix="projectTag"
            id="CREATE_PROJECT"
            label={t('project')}
            onClick={() => handleCreateSkillTagModalOpen(TagKind.Project)}
          />
          <ContextMenuItem
            iconPrefix="eventTag"
            id="CREATE_EVENT"
            label={t('event')}
            onClick={() => handleCreateSkillTagModalOpen(TagKind.Event)}
          />
          <ContextMenuItem
            iconPrefix="groupTag"
            id="CREATE_GROUP"
            label={t('group')}
            onClick={() => handleCreateSkillTagModalOpen(TagKind.Group)}
          />
          <ContextMenuItem
            iconPrefix="organizationGlyph"
            id="CREATE_ORGANIZATION"
            label={t('organization')}
            onClick={handleCreateOrganizationModalOpen}
          />
          <ContextMenuItem
            iconPrefix="customTag"
            id="CREATE_TAG"
            label={t('tag')}
            onClick={() => handleCreateSkillTagModalOpen(TagKind.Custom)}
          />
        </ContextMenu>
      </Styled.Wrapper>
      <CreateCommunityUserModal
        isOpen={createModal.isOpen}
        onClose={handleCreateModalClose}
      />
      <CreateSkillTagModal
        isOpen={createSkillTagModal.isOpen}
        skillTagType={skillTagType}
        onClose={handleCreateSkillTagModalClose}
      />
      <CreateOrganizationModal
        isOpen={createOrganizationModal.isOpen}
        onClose={handleCreateOrganizationModalClose}
      />
    </>
  )
}

export default CreateNewButton
