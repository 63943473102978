import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ApolloClient, useSubscription } from '@apollo/client'
import notificationPostedSubscription from 'GraphQL/Subscriptions/notificationPosted.graphql'
import { unreadNotificationsIncreaseCountUpdater } from 'GraphQL/Updaters/UnreadNotificationsCount'
import Utils from 'Utils'

import { InviteUserToCommunityModal } from 'Components/Blocks/Admin/Modals'
import { InvitesTable } from 'Components/Blocks/Admin/Tables'
import Header from 'Components/Blocks/Community/Header'
import GraphProvider from 'Components/Blocks/Graph/GraphProvider'
import ProfileModal from 'Components/Blocks/Modals/Profile'
import { NotificationsTable } from 'Components/Blocks/Tables'
import { Loader, Row } from 'Components/UI'

import {
  useAppContext,
  useCommunity,
  useEntityModal,
  useOnClickOutside,
} from 'Hooks'

import { QUICK_ACTIONS } from 'Pages/Community/Dashboard/constants'
import { IOptions } from 'Pages/Community/Dashboard/Context'

import {
  COMMUNITY,
  COMMUNITY_DASHBOARD,
  ONBOARDING_WELCOME,
} from 'Router/routes'

import CommunityContext from 'Services/CommunityContext'
import PermissionProvider from 'Services/Permission/Provider'

import NotMember from './NotMember'
import PrivacyAgreement from './PrivacyAgreement'
import {
  Container,
  Content,
  InviteTable,
  NotificationsContainer,
  PageContent,
  PrivacyContainer,
} from './styles'

function Community() {
  const notificationsRef = useRef<HTMLDivElement | null>(null)
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null)
  const invitationsRef = useRef<HTMLDivElement | null>(null)
  const invitationsButtonRef = useRef<HTMLButtonElement | null>(null)

  const location = useLocation()
  const navigate = useNavigate()
  const { isRestricted, me, canShowDashboard, isSuperAdmin } = useAppContext()
  const { community, loading: communityLoading, error } = useCommunity()
  const [inviteModal, inviteActions] = useEntityModal()

  const [noCommunity, setNoCommunity] = useState(false)
  const [notMember, setNotMember] = useState(false)
  const [tableInviteOpened, setTableInviteOpened] = useState(false)
  const [notificationsOpened, setNotificationsOpened] = useState(false)
  const [options, setOptions] = useState<IOptions | undefined>()

  useSubscription<Pick<MainSchema.Subscription, 'notificationsPayload'>>(
    notificationPostedSubscription,
    {
      shouldResubscribe: true,
      onData: ({ client }) => {
        unreadNotificationsIncreaseCountUpdater(
          client as ApolloClient<
            Pick<MainSchema.Subscription, 'notificationsPayload'>
          >,
          community?.id,
        )
      },
    },
  )

  useEffect(() => {
    if (error?.message === 'community.notMember') {
      setNotMember(true)
      navigate(COMMUNITY)
    }
  }, [error, navigate])

  useEffect(() => {
    if (!communityLoading && !community && !noCommunity) {
      setNoCommunity(true)
    }
  }, [noCommunity, communityLoading, community])

  useEffect(() => {
    if (!canShowDashboard && isRestricted) {
      navigate(COMMUNITY)
    }
  }, [isRestricted, navigate, canShowDashboard])

  const handleOpenInviteModal = inviteActions.openModal
  const handleCloseInviteModal = inviteActions.closeModal

  useOnClickOutside(
    notificationsRef,
    () => {
      if (notificationsOpened) setNotificationsOpened(false)
    },
    notificationsButtonRef,
  )

  useOnClickOutside(
    invitationsRef,
    () => {
      if (tableInviteOpened) setTableInviteOpened(false)
    },
    invitationsButtonRef,
  )

  const memoizedContext = useMemo(
    () => ({ community, noCommunity, communityLoading }),
    [community, communityLoading, noCommunity],
  )

  const dashboardPath = Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
    slug: community?.slug,
  })
  const isDashboard = location.pathname.startsWith(dashboardPath)

  const graphControls = useMemo(
    () => ({
      reset: isDashboard,
      options: false,
      myNetwork: isDashboard,
      graphSnapshots: isDashboard,
      search: isDashboard,
      browse: isDashboard,
    }),
    [isDashboard],
  )

  if (communityLoading) {
    return <Loader absolute />
  }

  if (!me?.onboardingCompletedAt && !me?.personalCommunityId) {
    navigate(ONBOARDING_WELCOME)
  }

  // TODO: Move to separate page and use redirect. Also isRestricted must be updated
  if (!me?.agreedToTermsAndConditions) {
    return (
      <CommunityContext.Provider value={memoizedContext}>
        <PermissionProvider>
          <Container>
            <Header isDashboard={isDashboard} menuHidden restricted />
            <Row fullHeight fullWidth justifyCenter p={5}>
              <PrivacyContainer>
                <PrivacyAgreement />
              </PrivacyContainer>
            </Row>
          </Container>
        </PermissionProvider>
      </CommunityContext.Provider>
    )
  }

  if (notMember && !isSuperAdmin) {
    return (
      <CommunityContext.Provider value={memoizedContext}>
        <PermissionProvider>
          <Container>
            <Header isDashboard={isDashboard} restricted />
            <Row fullHeight fullWidth>
              <NotMember />
            </Row>
          </Container>
        </PermissionProvider>
      </CommunityContext.Provider>
    )
  }

  return (
    <CommunityContext.Provider value={memoizedContext}>
      <PermissionProvider>
        <GraphProvider
          graphControls={graphControls}
          options={options}
          quickActions={QUICK_ACTIONS}
          setOptions={setOptions}
          showTargetOrganizations
          showTargetTags
          useQuickActions
        >
          <Container isDashboard={isDashboard}>
            {!isDashboard && (
              <Header isDashboard={isDashboard} restricted={isRestricted} />
            )}

            <Content isDashboard={isDashboard}>
              <NotificationsContainer
                opened={notificationsOpened}
                ref={notificationsRef}
              >
                {notificationsOpened && <NotificationsTable />}
              </NotificationsContainer>

              <InviteTable ref={invitationsRef} tableOpened={tableInviteOpened}>
                <InvitesTable onOpenInviteModal={handleOpenInviteModal} />
              </InviteTable>

              <PageContent>
                <Outlet />
              </PageContent>
            </Content>
          </Container>

          <ProfileModal />

          <InviteUserToCommunityModal
            isOpen={inviteModal.isOpen}
            onClose={handleCloseInviteModal}
          />
        </GraphProvider>
      </PermissionProvider>
    </CommunityContext.Provider>
  )
}

export default Community
