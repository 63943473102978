export const TABS = {
  PEOPLE: 'People',
  USERS: 'Users',
  ADMIN: 'Admin',
  API_KEYS: 'API Keys',
  HUBSPOTS: 'HubSpots',
  NOTES: 'Notes',
  ROLE_BASED_ACCESS: 'Role based access',
  SKILLS: 'Skills',
  TAGS: 'Tags',
  METRICS: 'Metrics',
  UPLOAD_DATA: 'Upload Data',
}
