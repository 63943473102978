export enum ProfileField {
  UserId = 'userId',
  CommunityId = 'communityId',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhotoUrl = 'photoUrl',
  Organization = 'organization',
  JobTitle = 'jobTitle',
  LinkedInUrl = 'linkedInUrl',
  TwitterUrl = 'twitterUrl',
  FacebookUrl = 'facebookUrl',
  PhoneNumber = 'phoneNumber',
  About = 'about',
  InterestsHobbies = 'interestsHobbies',
  Skills = 'skills',
  SkillIds = 'skillIds',
  NameStrength = 'nameStrength',
  ProfilePhotoStrength = 'profilePhotoStrength',
  EmailStrength = 'emailStrength',
  PhoneNumberStrength = 'phoneNumberStrength',
  SocialLinksStrength = 'socialLinksStrength',
  CompanyInfoStrength = 'companyInfoStrength',
  PublicNotesStrength = 'publicNotesStrength',
  SkillsTagsStrength = 'skillsTagsStrength',
  Events = 'events',
  Roles = 'roles',
  Projects = 'projects',
  Groups = 'groups',
  Custom = 'custom',
  Industries = 'industries',
  NeedIndustries = 'needIndustries',
}
