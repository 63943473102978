import { useCallback } from 'react'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, usePermission } from 'Hooks'

function useAbility() {
  const { me } = useAppContext()
  const { can } = usePermission()

  // Helpers

  const isCreatorMe = useCallback(
    (note: MainSchema.Note) => !!me && note.creatorId === me.userId,
    [me],
  )

  // Create

  const canCreatePublic = useCallback(() => {
    const result = can(
      PERMISSION_ACTION.CREATE,
      PERMISSION_SUBJECT.NOTE,
      PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
    )

    return result
  }, [can])

  const canCreatePrivate = useCallback(
    () =>
      can(
        PERMISSION_ACTION.CREATE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
      ),
    [can],
  )

  const canCreate = useCallback(
    () => canCreatePublic() || canCreatePrivate(),
    [canCreatePublic, canCreatePrivate],
  )

  // Edit

  const canCreatorEditToPublic = useCallback(
    () =>
      can(
        PERMISSION_ACTION.EDIT,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
      ),
    [can],
  )

  const canOtherEditToPublic = useCallback(
    () =>
      can(
        PERMISSION_ACTION.EDIT,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OTHER_CREATOR_PUBLIC,
      ),
    [can],
  )

  const canCreatorEditToPrivate = useCallback(
    () =>
      can(
        PERMISSION_ACTION.EDIT,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
      ),
    [can],
  )

  const canEditToPublic = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) ? canCreatorEditToPublic() : canOtherEditToPublic(),
    [isCreatorMe, canCreatorEditToPublic, canOtherEditToPublic],
  )

  const canEditToPrivate = useCallback(
    (note: MainSchema.Note) => isCreatorMe(note) && canCreatorEditToPrivate(),
    [isCreatorMe, canCreatorEditToPrivate],
  )

  const canCreatorEditNotes = useCallback(
    (note: MainSchema.Note) =>
      note.public ? canEditToPublic(note) : canEditToPrivate(note),
    [canEditToPublic, canEditToPrivate],
  )

  // Delete

  const canDeleteOwnPublic = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
      ),
    [can, isCreatorMe],
  )

  const canDeleteOwnPrivate = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      !note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
      ),
    [can, isCreatorMe],
  )

  const canDeleteOtherNotesPublic = useCallback(
    (note: MainSchema.Note) =>
      !isCreatorMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OTHER_CREATOR_PUBLIC,
      ),
    [can, isCreatorMe],
  )

  const canDelete = useCallback(
    (note: MainSchema.Note) =>
      canDeleteOwnPrivate(note) ||
      canDeleteOtherNotesPublic(note) ||
      canDeleteOwnPublic(note),
    [canDeleteOwnPrivate, canDeleteOtherNotesPublic, canDeleteOwnPublic],
  )

  return {
    // helpers
    isCreatorMe,
    // create
    canCreatePublic,
    canCreatePrivate,
    canCreate,
    // edit
    canCreatorEditToPublic,
    canOtherEditToPublic,
    canEditToPublic,
    canEditToPrivate,
    canCreatorEditNotes,
    // delete
    canDeleteOwnPublic,
    canDeleteOwnPrivate,
    canDeleteOtherNotesPublic,
    canDelete,
  }
}

export default useAbility
