import { SortInputOrder, TagKind } from './mainGraphQL'

export const USER_ROLE = {
  SUPER_ADMIN: 'superAdmin',
  USER: 'user',
}

export const TAG_KIND = {
  PROJECT: 'project',
  EVENT: 'event',
  ROLE: 'role',
  CUSTOM: 'custom',
  GROUP: 'group',
  INDUSTRY: 'industry',
}

export const WIZARD_STEPS = {
  WELCOME: 'welcome',
  PROFILE: 'profile',
  CONNECTIONS: 'connections',
  KNOWLEDGE: 'knowledge',
  NEEDS: 'needs',
  WORK: 'work',
  THANKS: 'thanks',
}

export const BREAKPOINTS_DIMENSIONS = {
  extraSmall: 450,
  small: 576,
  middle: 768,
  large: 1024,
  extraLarge: 1200,
}

export enum Locale {
  English = 'en',
}

export const UPLOAD_TYPES = {
  profilePhoto: 'profilePhoto',
  users: 'users',
  community: 'enrichCommunity',
  changeReport: 'changeReport',
  communityPhoto: 'communityPhoto',
} as const
export type UploadTypeKeys = keyof typeof UPLOAD_TYPES
export type UploadType = (typeof UPLOAD_TYPES)[UploadTypeKeys]

export const SOCIAL = {
  apple: 'apple',
  google: 'google',
}

export const SEARCH_TYPES = {
  knowledge: 'knowledge',
  person: 'person',
  user: 'user',
  skill: 'skill',
  organization: 'organization',
  community: 'community',
  role: 'role',
  event: 'event',
  project: 'project',
  custom: 'custom',
  group: 'group',
  industry: 'industry',
  all: 'all',
  tag: 'tag',
  suggestedPerson: 'suggestedPerson',
} as const
export type SearchTypeKeys = keyof typeof SEARCH_TYPES
export type SearchType = (typeof SEARCH_TYPES)[SearchTypeKeys]

export const INVITE_STATUSES = {
  ONBOARDING: 'onboarding',
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  ACTIVE: 'active',
  DECLINED: 'declined',
}

export const GRAPH_TAGS = {
  SKILLS: 'skills',
  PROJECTS: 'projects',
  EVENTS: 'events',
  ROLES: 'roles',
  GROUPS: 'groups',
  CUSTOM_TAGS: 'custom tags',
}

export const COMMUNITY_TYPES = {
  CREATOR: 'Creator',
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  AVAILABLE: 'Available',
}

export const COMMUNITY_USER_STATUS = {
  FULL: 'full',
  PENDING: 'pending',
  ARCHIVED: 'archived',
}

export const SORT_BY_LABELS = {
  ALPHABETICAL_A_Z: 'Alphabetical (A-Z)',
  ALPHABETICAL_Z_A: 'Alphabetical (Z-A)',
  RECENTLY_CREATED: 'Recently Created',
  OLDEST_CREATED: 'Oldest Created',
} as const
export type SortByLabelKeys = keyof typeof SORT_BY_LABELS
export type SortByLabel = (typeof SORT_BY_LABELS)[SortByLabelKeys]

export const SORT_BY_VALUES = {
  [SORT_BY_LABELS.ALPHABETICAL_A_Z]: {
    column: ['lastName'],
    order: [SortInputOrder.Asc],
  },
  [SORT_BY_LABELS.ALPHABETICAL_Z_A]: {
    column: ['lastName'],
    order: [SortInputOrder.Desc],
  },
  [SORT_BY_LABELS.RECENTLY_CREATED]: {
    column: ['createdAt'],
    order: [SortInputOrder.Desc],
  },
  [SORT_BY_LABELS.OLDEST_CREATED]: {
    column: ['createdAt'],
    order: [SortInputOrder.Asc],
  },
}

export const DEFAULT_MIN_SEARCH_SIZE = 3
export const DEFAULT_SEARCH_DEBOUNCE = 300

export const REDIRECT_BACK = {
  MANAGEMENT_NOTES: 'managementNotes',
}

export const USER_RELATIONSHIP_STRENGTH = {
  DONT_KNOW: 'dontKnow',
  WEAK: 'weak',
  MODERATE: 'moderate',
  STRONG: 'strong',
} as const
export type UserRelationshipStrengthKey =
  keyof typeof USER_RELATIONSHIP_STRENGTH
export type UserRelationshipStrength =
  (typeof USER_RELATIONSHIP_STRENGTH)[UserRelationshipStrengthKey]

export const RELATIONSHIP_STRENGTH_ENUM = {
  [USER_RELATIONSHIP_STRENGTH.DONT_KNOW]: 0,
  [USER_RELATIONSHIP_STRENGTH.WEAK]: 1,
  [USER_RELATIONSHIP_STRENGTH.MODERATE]: 2,
  [USER_RELATIONSHIP_STRENGTH.STRONG]: 3,
}

export const USER_RELATIONSHIP_LABELS = {
  [USER_RELATIONSHIP_STRENGTH.DONT_KNOW]: 'I don’t know them',
  [USER_RELATIONSHIP_STRENGTH.WEAK]: 'Know them, but not well',
  [USER_RELATIONSHIP_STRENGTH.MODERATE]: 'Personal or Professional Connection',
  [USER_RELATIONSHIP_STRENGTH.STRONG]: 'Trusted Relationship',
}

export const JOIN_COMMUNITY_LINK = 'https://collaboration.ai/contact-sales'

export const USER_PRIVACY_KIND = {
  NAME: 'name',
  PROFILE_PHOTO: 'profilePhoto',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  SOCIAL_LINKS: 'socialLinks',
  COMPANY_INFO: 'companyInfo',
  PUBLIC_NOTES: 'publicNotes',
  SKILLS_TAGS: 'skillsTags',
}

export const NOTIFICATION_KIND = {
  PROPOSAL: 'proposal',
  NYLAS_GRANT_EXPIRED: 'nylasGrantExpired',
}

export const PROPOSAL_KIND = {
  EMAIL: 'email',
  PHONE: 'phone',
  SKILL: 'skill',
  EVENT: 'event',
  PROJECT: 'project',
  GROUP: 'group',
  CUSTOM: 'custom',
  ROLE: 'role',
  CONNECTION: 'connection',
  LINKEDIN_URL: 'linkedInUrl',
  TWITTER_URL: 'twitterUrl',
  FACEBOOK_URL: 'facebookUrl',
}

export const PROPOSAL_STATUS = {
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  REJECTED: 'rejected',
}

// NOTE: Lib requires all lowercase strings
export const DRAG_DROP = {
  USER: 'user',
}

export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/Ai4collaboration',
  linkedIn: 'https://www.linkedin.com/company/collaboration.ai',
  sales: 'https://collaboration.ai/contact-sales',
  twitter: 'https://twitter.com/collaborationai',
}

export const EXTERNAL_LINKS = {
  collaborationAi: 'https://collaboration.ai',
  termsOfUse: 'https://collaboration.ai/privacy-policy',
  privacyPolicy: 'https://collaboration.ai/privacy-policy/#privacy-policy',
}

export const KEYS = {
  META: 'Meta',
  SHIFT: 'Shift',
  CONTROL: 'Control',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
}

export const EMAIL_CREDENTIAL_STATE = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
}

export enum SkillKind {
  Skill = 'skill',
  NeedsSkill = 'needsSkill',
}

export type SkillTagKind = SkillKind | TagKind
