import React, { useCallback } from 'react'

import { IconX } from '@tabler/icons-react'
import { fullNameOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import NoteForm from 'Features/Notes/Components/NoteForm'
import useNoteForm from 'Features/Notes/Components/NoteForm/useNoteForm'

import { Button, Column, Divider, Row } from 'Components/UI'
import { Chip, Text } from 'Components/UI/_v2'

import { useEventBusSubscribe } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import * as Styled from './styles'

export interface IQuickNotePanelProps {
  graphPersonNode: IGraphPersonNode
  onClose?: () => void
}

const QuickNotePanel: React.FC<IQuickNotePanelProps> = props => {
  const t = useScopedI18n('components.blocks.graph.quickActions.quickNotePanel')
  const {
    note,
    isLoading,
    formStatus,
    formValues,
    canToggleIsPublic,
    canSubmit,
    handleToggleIsFavorite,
    handleToggleIsPublic,
    handleAddMention,
    handleChangeContent,
  } = useNoteForm({
    communityUserId: props.graphPersonNode.communityUserId,
  })

  const handleClose = useCallback(async () => {
    props.onClose?.()
  }, [props])

  useEventBusSubscribe(EventBus.actions.dashboard.deleteNote, noteId => {
    if (noteId === note?.id) {
      props.onClose?.()
    }
  })

  if (isLoading) {
    return <></>
  }

  return (
    <Styled.Container>
      <Row center px={4} py={3} spaceBetween>
        <Text color={colors.text.primary} fontWeight={600} variant="body-xs">
          {t('heading')}
        </Text>

        <Styled.CloseButton title={t('close')} onClick={handleClose}>
          <IconX display="block" size={16} />
        </Styled.CloseButton>
      </Row>

      <Divider />

      <Row px={4} py={3}>
        <Row flexWrap="wrap" gap={2}>
          <Chip label={fullNameOrFallback(props.graphPersonNode)} />
        </Row>
      </Row>

      <Divider />

      <Row center px={4} py={3} spaceBetween>
        <NoteForm.IsPublicField
          isDisabled={!canToggleIsPublic}
          value={formValues.isPublic}
          onChange={handleToggleIsPublic}
        />

        <NoteForm.IsFavoriteField
          value={formValues.isFavorite}
          onChange={handleToggleIsFavorite}
        />
      </Row>

      <Column px={4}>
        <NoteForm.ContentField
          communityUserId={props.graphPersonNode.communityUserId}
          existingMentions={formValues.existingMentions}
          value={formValues.content}
          onAddMention={handleAddMention}
          onChangeContent={handleChangeContent}
        />
      </Column>

      <Row center px={4} py={3} spaceBetween>
        <NoteForm.StatusMessage status={formStatus} />

        <Button disabled={!canSubmit} minWidth="80px" onClick={handleClose}>
          {t('submit')}
        </Button>
      </Row>
    </Styled.Container>
  )
}

export default QuickNotePanel
